<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div style="margin-bottom: 40px">
        <p>Date journée</p>
        <span class="span" style="margin-left: 30px">
          <label>
            Entre
            <a-input
              id="dateDebutJournée"
              type="date"
              name="dateDebutJournée"
              style="width: 300px"
            />
          </label>
          <label class="space">
            Et
            <a-input
              id="dateFinJournée"
              type="date"
              name="dateDebutJournée"
              style="width: 300px"
            />
          </label>
        </span>
      </div>
      <div>
        <a-button
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <a-button type="user-add" @click="showModal">
          <a-icon type="user-add" @click="showModal" />{{
            $t("action.ajouter")
          }}
        </a-button>
        <div>
          <a-modal
            v-model="visibleModal"
            @cancel="handleCancel"
            :title="$t('all.enterInformationBelow')"
            :width="750"
            :height="1200"
            :footer="false"
          >
            <a-form :form="form" class="container" @submit="addUtilisateur">
              <div class="row">
                <div class="col-lg-6">
                  <a-form-item label="Désignation">
                    <a-select
                      show-search
                      :autoFocus="true"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      placeholder="Désignation"
                      v-decorator="[
                        'designation',
                        {
                          rules: [
                            {
                              required: true,
                              message: `Le désignation  est requis`,
                            },
                          ],
                        },
                      ]"
                    >
                    </a-select>
                  </a-form-item>
                </div>
                <div class="col-lg-6">
                  <a-form-item label="Type recette">
                    <a-select
                      show-search
                      :placeholder="$t('comptabilite.typeRecette')"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur"
                      @change="handleChangeSelectEtatCivile"
                      v-decorator="[
                        'typeRecette',
                        {
                          rules: [
                            {
                              required: true,
                              message:
                                'Le type de recette doit être selectionné',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-select-option value="jack"> Chéque </a-select-option>
                      <a-select-option value="lucy"> Espéce </a-select-option>
                      <a-select-option value="tom"> Scolarité </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <a-form-item label="Du">
                    <a-input
                      placeholder="Du"
                      v-decorator="[
                        'dateDebut',
                        {
                          rules: [
                            {
                              required: true,
                              message: `La date de début est requis`,
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
                <div class="col-lg-6">
                  <a-form-item label="Au">
                    <a-input
                      placeholder="Au"
                      v-decorator="[
                        'dateFin',
                        {
                          rules: [
                            {
                              required: true,
                              message: `La date de fin est requis`,
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <a-form-item :label="$t('recette.montant')">
                    <a-input
                      :placeholder="$t('recette.montant')"
                      v-decorator="[
                        'montant',
                        {
                          rules: [
                            {
                              required: true,
                              message: `Le montant
                    est requis`,
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
                <div class="col-lg-6">
                  <a-form-item label="Observation">
                    <a-input
                      placeholder="Observation"
                      v-decorator="[
                        'observation',
                        {
                          rules: [
                            {
                              required: true,
                              message: `La observation est requis`,
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="form-actions mt-0">
                <a-button
                  style="width: 150px"
                  type="primary"
                  htmlType="submit"
                  :loading="loadingUtilisateur"
                  class="mr-3"
                >
                  {{ $t("action.ajouter") }}
                </a-button>
                <a-button
                  key="back"
                  @click="handleCancel"
                  :disabled="loadingUtilisateur"
                >
                  {{ $t("action.annuler") }}
                </a-button>
              </div>
            </a-form>
          </a-modal>
        </div>
      </div>
      <div>
        <a-table
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :scroll="{ x: 3000 }"
        >
          <template
            v-for="col in [
              'idCaisse',
              'libelle',
              'dateDebut',
              'dateFin',
              'typeRecette',
              'montant',
              'dateEnregistrement',
              'userEnregistrement',
              'etablissement',
            ]"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="(e) => handleChange(e.target.value, record.key, col)"
              />
              <template v-else>{{ text }}</template>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => save(record.key)">{{ $t("all.save") }}</a>
                <a-popconfirm
                  title="Sure to cancel?"
                  @confirm="() => cancel(record.key)"
                >
                  <a>{{ $t("action.annuler") }}</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a-button
                  type="primary"
                  :disabled="editingKey !== ''"
                  @click="() => edit(record.key)"
                >
                  <a-icon
                    type="edit"
                    :disabled="editingKey !== ''"
                    @click="() => edit(record.key)"
                  />{{ $t("action.modifier") }}
                </a-button>
              </span>
            </div>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              title="Sure to delete?"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.supprimer")
                }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import axios from "axios";
import JsPDF from "jspdf";
import "jspdf-autotable";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const fileList = [
  {
    uid: "-1",
    name: "",
    status: "done",
    url: "../../../resources/images/eleve.jpg",
  },
];

const data = [
  {
    key: "1",
    fournisseurs: "khaled",
    typeDepense: "khaled",
    etablissement: "etablissement 1",
    dateDepense: "dateDepense 1",
    montant: "Payé",
    mois: "Class1",
    observation: "y",
    modePaiement: "b",
  },
  {
    key: "2",
    fournisseurs: "hamza",
    typeDepense: "khaled",
    etablissement: "etablissement 2",
    dateDepense: "dateDepense 2",
    montant: "Non Payé",
    mois: "Class2",
    observation: "y",
    modePaiement: "b",
  },
  {
    key: "3",
    fournisseurs: "tayssir",
    typeDepense: "khaled",
    etablissement: "etablissement 2",
    dateDepense: "dateDepense 3",
    montant: "Payé",
    mois: "Class3",
    observation: "y",
    modePaiement: "b",
  },
  {
    key: "4",
    fournisseurs: "amal",
    typeDepense: "khaled",
    etablissement: "etablissement 3",
    dateDepense: "dateDepense 4",
    montant: "Non Payé",
    mois: "Class4",
    observation: "y",
    modePaiement: "b",
  },
];
export default {
  data() {
    this.cacheData = data.map((item) => ({ ...item }));
    return {
      editingKey: "",
      visibleParentFile: false,
      data,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      form: this.$form.createForm(this),
      previewImage: "",
      fileList,
      value: 1,
      value1: 1,
      loadingData: false,
      visibleModal: false,
      columns: [
        {
          title: "Fournisseurs",
          dataIndex: "fournisseurs",
          key: "fournisseurs",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "fournisseurs",
          },
          onFilter: (value, record) =>
            record.fournisseurs.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Type de dépense",
          dataIndex: "typeDepense",
          key: "typeDepense",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeDepense",
          },
          onFilter: (value, record) =>
            record.typeDepense.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Etablissement",
          dataIndex: "etablissement",
          key: "etablissement",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "etablissement",
          },
          onFilter: (value, record) =>
            record.etablissement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Date de ddéense",
          dataIndex: "dateDepense",
          key: "dateDepense",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "dateDepense",
          },
          onFilter: (value, record) =>
            record.dateDepense.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Montant",
          dataIndex: "montant",
          key: "montant",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "montant",
          },
          onFilter: (value, record) =>
            record.montant.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Mois",
          dataIndex: "mois",
          key: "mois",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "mois",
          },
          onFilter: (value, record) =>
            record.mois.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Obsérvation",
          dataIndex: "observation",
          key: "observation",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "observation",
          },
          onFilter: (value, record) =>
            record.observation.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Mode de paiement",
          dataIndex: "modePaiement",
          key: "modePaiement",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) =>
            record.modePaiement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
    };
  },
  methods: {
    handleAjouter(e) {
      var newFournisseurs = $("#fournisseurs").val();
      var newTypeDepense = $("#typeDepense").val();
      var newEtablissement = $("#etablissement").val();
      var newDateDepense = $("#dateDepense").val();
      var newMontant = $("#montant").val();
      var newMois = $("#mois").val();
      var newObservation = $("#observation").val();
      var newModePaiement = $("#modePaiement").val();

      var text = $("#input").val();
      if (this.data.length === 0) {
        var key = "0";
      } else {
        var key = this.data[this.data.length - 1].key;
      }
      var key1 = Number(key) + 1;
      this.data.push({
        key: key1,
        name: text,
        etablissement: this.activitySelected,
        parent: "",
        telephone: "",
        etat: "",
        class: "",
        fournisseurs: newNom,
        typeDepense: newTypeDepense,
        etablissement: newEtablissement,
        montant: newMontant,
        mois: newMois,
        observation: newObservation,
        modePaiement: newModePaiement,
      });
      this.loadingData = true;
      setTimeout(() => {
        this.visibleModal = false;
        this.loadingData = false;
      }, 3000);
    },
    handleCancel(e) {
      this.visibleModal = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    getMock() {
      const targetKeys = [];
      const mockData = [];
      for (let i = 0; i < 20; i++) {
        const data = {
          key: i.toString(),
          title: `content${i + 1}`,
          description: `description of content${i + 1}`,
          chosen: Math.random() * 2 > 1,
        };
        if (data.chosen) {
          targetKeys.push(data.key);
        }
        mockData.push(data);
      }
      this.mockData = mockData;
      this.targetKeys = targetKeys;
    },
    generatePdf() {
      var imgData = new Image();
      imgData.src = "../../../resources/images/logo.png";
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      //       doc.setFontSize(20)
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      for (let i = 0; i < this.data.length; i++) {
        arr.push(this.data[i].nom);
        arr.push(this.data[i].prenom);
        arr.push(this.data[i].qualification);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        margin: { top: 70 },
        head: [["Name", "Email", "Country"]],
        body: array,
      });

      doc.save("table.pdf");
    },
    showModal() {
      this.visibleModal = true;
    },

    handleCancelImage() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeImage({ fileList }) {
      this.fileList = fileList;
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
